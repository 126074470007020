const servicePeriodState = {
   servicePeriods: []
};

const servicePeriodReducer = (state = servicePeriodState, action) => {
   switch (action.type) {
      case "GET_SERVICE_PERIODS":
         return { ...state, servicePeriods: action.data };
      default:
         return { ...state };
   }
};

export default servicePeriodReducer;