import request from "../../utils/request";
import { message } from "antd";

export const getUsers = () => {
  return async (dispatch) => {
    await request.get('/users').then((response) => {
      dispatch({
        type: "GET_USERS",
        data: response.data.data || [],
      });
    });
  };
};

export const addUser = (user) => {
  return (dispatch, getState) => {
    request
      .post("/users", user)
      .then((response) => {
        message.success('Add Success');
        dispatch({
          type: "ADD_USER",
          user,
        });
      })
      .then(() => {
        dispatch(getUsers());
      })
      .catch((err) => console.log(err));
  };
};

export const deleteUser = (id) => {
  return (dispatch, getState) => {
    request
      .delete("/users/" + id)
      .then(() => {
        message.success('Delete Success');
        dispatch({
          type: "DELETE_USER",
        });
      })
      .then(() => {
        dispatch(getUsers());
      });
  };
};

export const editUser = (id, user) => {
  return (dispatch) => {
    request
      .patch("/users/" + id, user)
      .then(() => {
        message.success('Edit Success');
        dispatch({
          type: "EDIT_USER",
        });
      })
      .then(() => {
        dispatch(getUsers());
      });
  };
};

export const getUser = (record) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_USER",
      selectedUser: record,
    });
  };
};