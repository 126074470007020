import { 
    AiOutlineDashboard,
    AiOutlineUser,
    AiOutlineAccountBook
} from "react-icons/ai";
import { 
    IoRestaurantOutline,
    IoStatsChartSharp
} from "react-icons/io5";
import { RiBaseStationLine, RiSettings3Line } from "react-icons/ri";
import { GrOrderedList } from "react-icons/gr";
import { BiFoodMenu } from "react-icons/bi";
import { MdOutlineMonitorWeight } from "react-icons/md";

const pages = [
    {
        id: "executiveInsights",
        title: "Executive Insights",
        icon: <AiOutlineDashboard set="curved" className="remix-icon" />,
        children: [
            {
                id: "executiveDashboard",
                title: "Dashboard",
                navLink: "/pages/executive/dashboard",
            },
            {
                id: "orderAnalysis",
                title: "Order Analysis",
                navLink: "/pages/executive/portion",
            },
        ],
        access: 'account admin',
    },
    {
        id: "account",
        title: "Account",
        icon: <AiOutlineAccountBook set="curved" className="remix-icon" />,
        navLink: "/pages/account",
        access: 'system admin',
    },
    {
        id: "user",
        title: "User",
        icon: <AiOutlineUser set="curved" className="remix-icon" />,
        navLink: "/pages/user",
        access: 'system admin',
    },
    {
        id: "restaurant",
        title: "Restaurant",
        icon: <IoRestaurantOutline set="curved" className="remix-icon" />,
        navLink: "/pages/restaurant",
        access: 'system admin',
    },
    {
        id: "menuItems",
        title: "Menu Management",
        icon: <BiFoodMenu set="curved" className="remix-icon" />,
        children: [
            {
                id: "menuList",
                title: "Menu Items",
                navLink: "/pages/menuitem",
            },
            {
                id: "menuCategories",
                title: "Menu Categories",
                navLink: "/menu/categories",
            },
        ],
        access: 'account admin',
    },
    {
        id: "foodMonitoring",
        title: "Food Monitoring",
        icon: <MdOutlineMonitorWeight set="curved" className="remix-icon" />,
        navLink: "/pages/dashboard/culinarian",
        access: 'account admin'
    },
    {
        id: "orders",
        title: "Orders",
        icon: <GrOrderedList set="curved" className="remix-icon" />,
        navLink: "/pages/portion",
        access: 'account admin',
    },
    {
        id: "station",
        title: "Smart Station",
        icon: <RiBaseStationLine set="curved" className="remix-icon" />,
        navLink: "/pages/station",
    },
    {
        id: "settings",
        title: "Settings",
        icon: <RiSettings3Line set="curved" className="remix-icon" />,
        navLink: "/pages/restaurant-pref",
        access: 'account admin',
    },
];

export default pages;