import { combineReducers } from "redux";

import accountReducer from "./account/accountReducer";
import calendarReducer from "./calendar/calendarReducer";
import customiseReducer from "./customise/customiseReducer";
import iotscaleReducer from "./iotscale/iotscaleReducer";
import loginReducer from "./login/loginReducer";
import menuItemCategoryReducers from "./menuItemCategory/menuItemCategoryReducers";
import menuitemReducer from "./menuitem/menuitemReducer";
import restaurantReducer from "./restaurant/restaurantReducer";
import servicePeriodReducer from "./servicePeriod/servicePeriodReducer";
import stationReducer from "./station/stationReducer";
import userReducer from "./user/userReducer";
import orderReducer from "./order/orderReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  calendar: calendarReducer,
  customise: customiseReducer,
  iotscale: iotscaleReducer,
  login: loginReducer,
  menuItemCategory: menuItemCategoryReducers,
  menuitem: menuitemReducer,
  restaurant: restaurantReducer,
  servicePeriod: servicePeriodReducer,
  station: stationReducer,
  user: userReducer,
  order: orderReducer,
});

export default rootReducer;