const orderState = {
  orders: [],
  response: {},
  total: 1
};
  
const orderReducer = (state = orderState, action) => {
  switch (action.type) {
    case "GET_ORDERS":
      return { ...state, orders: action.data, total: action.total};

    case "CHARGE_ORDER":
      return { ...state, response: action.data};

    case "REFUND_ORDER":
      return { ...state, response: action.data};

    case "ADD_ORDER":
        return { ...state};

    default:
      return { ...state };
  }
};
  
export default orderReducer;