import React, { useEffect, useState } from "react";

import { awsRefresh } from "../utils/tool";
import { useSelector, useDispatch } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import jwt_decode from"jwt-decode";

const { Content } = Layout;
var taskCheckToken = null;

export default function VerticalLayout(props) {
      // Redux
    const dispatch = useDispatch();
    const { children } = props;

    const [visible, setVisible] = useState(false);

    // Redux
    const customise = useSelector(state => state.customise)
    const currentUser = useSelector(state => state.login.currentUser)

    function logout() {
        window.location.href = ('/pages/login');
        localStorage.setItem('token', '')
        localStorage.setItem('lastRequestTime', '')
        dispatch({
          type: "GET_CURRENT_USER",
          currentUser: null
        });
    }

    async function checkToken() {
        let tmpToken = localStorage.getItem('token')
        let lastRequestTime = localStorage.getItem('lastRequestTime')
        let exp = jwt_decode(tmpToken).exp
        let iat = jwt_decode(tmpToken).iat
        let vaildIntervalTime = (exp - iat) * 1000
        if (!tmpToken) {
            logout()
        }
        if (lastRequestTime) {
            lastRequestTime = parseInt(lastRequestTime)
        } else {
            lastRequestTime = 0
        }
        if (taskCheckToken) {
            clearTimeout(taskCheckToken)
            taskCheckToken = null
        }
        let currentIntervalTime = Date.now() - lastRequestTime
        if (currentIntervalTime >= vaildIntervalTime) {
            logout()
        } else {
            console.log('refreshTime', vaildIntervalTime - currentIntervalTime)
            await awsRefresh(currentUser)
            taskCheckToken = setTimeout(()=>{
                checkToken()
            }, vaildIntervalTime - currentIntervalTime)
        }
    }
    useEffect(() => {
        checkToken()
        return () => {
            if (taskCheckToken) {
                clearTimeout(taskCheckToken)
                taskCheckToken = null
            }
        }
      }, [])

    return (
        <Layout className="da-app-layout">
            <Sidebar visible={visible} setVisible={setVisible} />

            <Layout className="da-bg-color-dark-90">
                <MenuHeader setVisible={setVisible} />

                <Content className="da-content-main">
                    <Row justify="center">
                        {
                            customise.contentWidth == "full" && (
                                <Col span={24}>
                                    {children}
                                </Col>
                            )
                        }

                        {
                            customise.contentWidth == "boxed" && (
                                <Col xxl={20} xl={22} span={24}>
                                    {children}
                                </Col>
                            )
                        }
                    </Row>
                </Content>

                <MenuFooter />
            </Layout>

            <ScrollTop />
        </Layout>
    );
}