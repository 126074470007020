const iotscalesState = {
    iotscales: []
  };
  
  const iotscaleReducer = (state = iotscalesState, action) => {
    switch (action.type) {
      case "GET_IOTSCALES":
        return { ...state, iotscales: action.data };
  
      default:
        return { ...state };
    }
  };
  
  export default iotscaleReducer;