import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/pages/dashboard/executive",
    component: lazy(() => import("../../view/pages/dashboard/executive")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dashboard/culinarian",
    component: lazy(() => import("../../view/pages/dashboard/culinarian")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dashboard/kitchen",
    component: lazy(() => import("../../view/pages/dashboard/kitchen")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/executive/dashboard",
    component: lazy(() => import("../../view/pages/executive/dashboard")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/executive/leftover",
    component: lazy(() => import("../../view/pages/executive/leftover")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/executive/portion",
    component: lazy(() => import("../../view/pages/executive/portion")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/account",
    component: lazy(() => import("../../view/pages/account")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/user",
    component: lazy(() => import("../../view/pages/user")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/restaurant",
    component: lazy(() => import("../../view/pages/restaurant")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/station",
    component: lazy(() => import("../../view/pages/station")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/menuitem",
    component: lazy(() => import("../../view/pages/menuitem")),
    layout: "VerticalLayout",
  },
  {
    path: "/menu/categories",
    component: lazy(() => import("../../view/pages/menuitemcategory")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/menuitem-plan",
    component: lazy(() => import("../../view/pages/menuitem-plan")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/portion",
    component: lazy(() => import("../../view/pages/portion")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/restaurant-pref",
    component: lazy(() => import("../../view/pages/restaurant-pref")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/login",
    component: lazy(() => import("../../view/pages/login")),
    layout: "FullLayout",
  },
  {
    path: "/pages/sso",
    component: lazy(() => import("../../view/pages/sso")),
    layout: "FullLayout",
  },
  {
    path: "/pages/culinarian",
    component: lazy(() => import("../../view/pages/culinarian")),
    layout: "FullLayout",
  },
  {
    path: "/pages/squarecallback",
    component: lazy(() => import("../../view/pages/squarecallback")),
    layout: "FullLayout",
  }
];

export default PagesRoutes;