export const errorCode = {
  Success:    { code: 0,      msg: 'Success'                  },
  Fail:       { code: -1,     msg: 'Operation failed'         },
  Auth:       { code: -2,     msg: 'Authentication failed'    },
  Param:      { code: -3,     msg: 'Invalid parameter'        },
  Access:     { code: -4,     msg: 'Access denied'            },
  Exist:      { code: -5,     msg: 'Target exist'             },
  Impl:       { code: -6,     msg: 'Not implemented'          },
  AWS:        { code: -7,     msg: 'AWS error'                },
  SquareAuth: { code: -12,    msg: 'Square Connection Failed' },
}