const initialState = {
    users: [],
    data: [],
    total: 1,
    params: {},
    selectedUser: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_USERS":
        return { ...state, users: action.data };
  
      case "GET_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "GET_USER":
        return { ...state, selectedUser: action.selectedUser };
  
      case "ADD_USER":
        return { ...state };
  
      case "EDIT_USER":
        return { ...state };

      case "DELETE_USER":
        return { ...state };
  
      default:
        return { ...state };
    }
  };
  
  export default userReducer;