const loginState = {
    currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
};

const loginReducer = (state = loginState, action) => {
    switch (action.type) {
        case "GET_CURRENT_USER":
            localStorage.setItem('currentUser', JSON.stringify(action.currentUser))
            return {
                ...state,
                currentUser: action.currentUser,
            };

        default:
            return state;
    }
};

export default loginReducer;