import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import skoopin from "../../../../assets/images/logo/logo.png";

export default function MenuLogo(props) {
  const customise = useSelector(state => state.customise)
  const currentUser = useSelector(state => state.login.currentUser)

  return (
    <Link
      to={currentUser.Role === 'system admin' ? '/pages/dashboard/kitchen' : '/pages/executive/dashboard'}
      className="da-header-logo da-d-flex da-align-items-center"
      onClick={props.onClose}
    >
      <img className="da-logo" src={skoopin} alt="logo" style={{height: '60px'}}/>
    </Link>
  );
}