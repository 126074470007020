import request from "./request";
import {
    CognitoUserPool,
    CognitoRefreshToken,
    CognitoUser,
} from 'amazon-cognito-identity-js';
import _ from 'lodash';
import { Blob } from 'buffer';

const systemEmail = 'system@skoopin.com'

export const previewLimit = 7;

export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            return false;
        }
    }
}

export function toDecimal2(x) {
    let ret_value;
    try {
        ret_value = Number(x).toFixed(2);
    } catch (e) { }
    if (isNaN(ret_value)) {
        ret_value = undefined;
    }
    return ret_value;
}

export async function awsRefresh(currentUser) {
    let config = await request.get('/auth/configuration');
    if (config && config.data && config.data.data.aws) {

        let promise = new Promise(function (resolve, reject) {
            var poolData = {
                UserPoolId: config.data.data.AWS_USER_POOL_ID,
                ClientId: config.data.data.AWS_CLIENT_ID,
            };
            const userData = {
                Username: currentUser ? currentUser.Email : systemEmail,
                Pool: new CognitoUserPool(poolData),
            }
            let refreshToken = new CognitoRefreshToken({
                RefreshToken: localStorage.getItem('refreshToken')
            })
            let cognitoUser = new CognitoUser(userData);
            cognitoUser.setAuthenticationFlowType('REFRESH_TOKEN_AUTH');
            cognitoUser.refreshSession(refreshToken, (err, result) => {
                if (err) {
                    reject(err)
                    return;
                }
                resolve(result);
            })
        })
        let awsres = await promise;
        localStorage.setItem('token', awsres.accessToken.jwtToken);
        localStorage.setItem('refreshToken', awsres.refreshToken.token);
    }
}

export async function sortData(data, type, order) {
    if (order == 'asc') {
        data.sort((a, b) => a[type] - b[type]);
    } else if (order == 'desc') {
        data.sort((a, b) => b[type] - a[type]);
    }
}
export async function sortPercentageData(data, type1, type2, order) {
    data.forEach(item => {
        if (isNaN(item[type1])) {
            item[type1] = 0
        }
        if (isNaN(item[type2])) {
            item[type2] = 0
        }
    })
    if (order == 'asc') {
        data.sort((a, b) => (a[type1] / a[type2]) - (b[type1] / b[type2]));
    } else if (order == 'desc') {
        data.sort((a, b) => (b[type1] / b[type2]) - (a[type1] / a[type2]));
    }
}

export async function sortChartData(chartData, index) {
    let list = [];
    for (var j = 0; j < chartData.Consumed.length; j++) {
        list.push({
            'refills': chartData.refills[j], 'categories': chartData.categories[j], 'StationIDs': chartData.StationIDs[j],
            'Consumed': chartData.Consumed[j], 'Leftovers': chartData.Leftovers[j], 'MenuItemID': chartData.MenuItemID[j],
            'portions': chartData.portions[j], 'dailyAvePortionSize': chartData.dailyAvePortionSize[j]
        });
    }
    list.sort(function (a, b) {
        switch (index) {
            case "Refills":
                return ((a.refills < b.refills) ? 1 : ((a.refills == b.refills) ? 0 : -1));
            case "Consumed":
                return ((a.Leftovers / a.Consumed < b.Leftovers / b.Consumed) ? 1 : ((a.Leftovers / a.Consumed == b.Leftovers / b.Consumed) ? 0 : -1));
            case "Portions":
                return ((a.portions < b.portions) ? 1 : ((a.portions == b.portions) ? 0 : -1));
            case "AvgPortionSize":
                return ((a.dailyAvePortionSize < b.dailyAvePortionSize) ? 1 : ((a.dailyAvePortionSize == b.dailyAvePortionSize) ? 0 : -1));
            default:
                return 1;
        }
    });
    for (var k = 0; k < chartData.refills.length; k++) {
        chartData.refills[k] = list[k].refills;
        chartData.categories[k] = list[k].categories;
        chartData.StationIDs[k] = list[k].StationIDs;
        chartData.Consumed[k] = list[k].Consumed;
        chartData.Leftovers[k] = list[k].Leftovers;
        chartData.MenuItemID[k] = list[k].MenuItemID;
        chartData.portions[k] = list[k].portions;
        chartData.dailyAvePortionSize[k] = list[k].dailyAvePortionSize;
    }
}

export function downloadBlob(content, filename) {
    const blob = new Blob([content]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

export function categoryCompareFunc(a, b) {
    const categoryOrder = [
        'Entree',
        'Starch',
        'Vegetable',
        'Side',
        'Soup',
        'Salad',
        'Salad Builder',
        'Sauce',
    ];

    let aOrder = categoryOrder.indexOf(a);
    let bOrder = categoryOrder.indexOf(b);
    if (aOrder === -1) aOrder = 9999;
    if (bOrder === -1) bOrder = 9999;
    let ret = aOrder - bOrder;
    if (ret === 0) {
        ret = a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    }
    return ret;
}

export function sortByCategoryName(arr, categoryNamePath) {
    arr.sort((a, b) => {
        const aCategory = _.get(a, categoryNamePath);
        const bCategory = _.get(b, categoryNamePath);
        return categoryCompareFunc(aCategory, bCategory);
    });
}