import React, { useEffect, useState } from "react";
import { Card, Col, Row, Dropdown, Menu, Radio } from 'antd';
import Chart from "react-apexcharts";
import { useHistory } from 'react-router-dom'
import { sortData } from "../../../../../utils/tool";

export default function Consumption(props) {
  const { consumptionData } = props;
  const history = useHistory()
  const [chartType, setChartType] = useState();
  const [chartData, setChartData] = useState();
  const fixedLegends = {
    'Porridge': "#0010F7",
    'Spanish omelets': "#55B1F3",
    'Boiled eggs': "#1BE7FF",
    'Hash brown potatoes': "#ED7D31",
    'Blueberry pancakes': "#55B1F3",
    'Grilled pork and veal sausage': "#264478",
    'Breakfast steaks with fried': "#FFAEC9",
    'French toast': "#C8BFE7",
    'oriental scrambled eggs with tomatoes and feta cheese': '#B97A57',
    'Roast honey glazed ham': '#FFF200',
    'Salad': '#BAE23E',
    'Other': '#A349A4'
  };

  const dynamicLegends = ['#0010F7', '#55B1F3', '#1BE7FF',
    '#ED7D31', '#55B1F3', '#264478', '#FFAEC9', '#C8BFE7', '#B97A57', '#FFF200', '#BAE23E', '#A349A4'];

  useEffect(() => {
    setChartType(localStorage.getItem('chartType') || 'ByWeight')
    if (!consumptionData || !chartType) {
      return;
    }
    let consumptionDataArray = Object.values(consumptionData);
    const _chartData = {
      series: [],
      options: {
        // options
        chart: {
          fontFamily: "Manrope, sans-serif",
          type: "donut",
          events: { // 添加柱状图数据的点击事件
            dataPointSelection: function (event, chartContext, config) {
              history.push({pathname: '/pages/executive/portion', state: {
                initMenuItemID: consumptionDataArray[config.dataPointIndex]
              }})
            }
          },
          zoom: {
            enabled: false,
          },
        },
        colors: [],
        labels: [],
        dataLabels: {
          enabled: false,
        },
        title: {
          text: consumptionDataArray && consumptionDataArray.length > 0 ? '' : 'No Data',
          align: 'center',
          offsetY: 100,
          style: {
            fontSize:  '20px',
            fontWeight: 'light',
            color:  '#C3C3C3'
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function(val, obj) {
              if (chartType === 'ByWeight') {
                return val.toFixed(1) + ' lb';
              } else {
                return val.toFixed(0);
              }
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: {
                  fontSize: "15px",
                  formatter: function (value) {
                    let tmp = value.split(' ')
                    if(tmp.length > 3){
                      return tmp[0] + " " + tmp[1] + " " + tmp[2] + '...';
                    } else {
                      return value;
                    }
                  },
                },
                value: {
                  fontSize: "13px",
                  formatter(val) {
                    if (consumptionDataArray && consumptionDataArray.length > 0) {
                      if (chartType === 'ByWeight') {
                        let totalWeight = 0
                        for(let m = 0; m < consumptionDataArray.length; m++) {
                          totalWeight += consumptionDataArray[m].TotalWeight
                        }
                        return totalWeight !== 0 ? `${(parseFloat(val) / totalWeight * 100).toFixed(1)} %` : '0%';
                      } else {
                        let totalOrderDeatil = 0
                        for(let m = 0; m < consumptionDataArray.length; m++) {
                          totalOrderDeatil += consumptionDataArray[m].TotalOrderDetails
                        }
                        return totalOrderDeatil !== 0 ? `${(parseFloat(val) / totalOrderDeatil * 100).toFixed(1)} %` : '0%';
                      }
                    } else {
                      return '0%'
                    }
                  },
                },
                total: {
                  show: false
                },
              },
            },
          },
        },
        legend: {
          itemMargin: {
            horizontal: 24,
            vertical: 0,
          },
          horizontalAlign: "center",
          position: "bottom",
          fontSize: "12px",
          formatter: function (value) {
            let tmp = value.split(' ')
            if(tmp.length > 3){
              return tmp[0] + " " + tmp[1] + " " + tmp[2] + '...';
            } else {
              return value;
            }
          },
          markers: {
            radius: 12,
          },
        },
      },
    }
    let index = 0;
    _chartData.series = [];
    _chartData.options.labels = [];
    _chartData.options.colors = [];

    let dataKey = 'TotalWeight';
    if (chartType === 'ByWeight') {
      dataKey = 'TotalWeight';
    } else if (chartType === 'ByPortion') {
      dataKey = 'TotalOrderDetails';
    }

    sortData(consumptionDataArray, dataKey, 'desc');

    for (const menuItem of consumptionDataArray.slice(0, 11)) {
      _chartData.series.push(menuItem[dataKey]);
      _chartData.options.colors.push(fixedLegends[menuItem.MenuItemName] || dynamicLegends[index++]);
      _chartData.options.labels.push(menuItem.MenuItemName);
    }

    if (consumptionDataArray.length > 11) {
      let otherValue = 0
      const otherMenuItems = {
        MenuItemName: 'Other',
        MenuItemID: [],
      };
      for (const menuItem of consumptionDataArray.slice(11)) {
        otherValue += menuItem[dataKey];
        otherMenuItems.MenuItemID.push(menuItem);
      }
      consumptionDataArray = consumptionDataArray.slice(0, 11);
      otherMenuItems[dataKey] = otherValue;
      consumptionDataArray.push(otherMenuItems);
      _chartData.series.push(otherValue);
      _chartData.options.colors.push(fixedLegends['Other']);
      _chartData.options.labels.push('Other');
    }
    setChartData(_chartData);
  }, [chartType, consumptionData]);

  const onChange = (e) => {
    setChartData();
    setChartType(e.target.value);
    localStorage.setItem('chartType', e.target.value)
  };

  return (
    <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
      <Row style={{height: '430px'}}>
        <Col span={24}>
          <Row justify="space-between" align="top">
            <Col>
              <h4 className="da-mr-8">Consumption By Menu</h4>
            </Col>
            <Col>
              <Radio.Group onChange={onChange} value={chartType}>
                <Radio value={'ByWeight'}>By Weight</Radio>
                <Radio value={'ByPortion'}>By Portion</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div style={{minHeight: '315px'}}>
            { chartData &&
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height={430}
                legend="legend"
              />
            }
          </div>
        </Col>
      </Row>
    </Card>
  );
}