import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import PwdModal from "./PwdModal";
import { Menu, Dropdown, Col, Avatar } from "antd";
import {
  Logout,
} from "react-iconly";
import {
  RiLockPasswordLine
} from "react-icons/ri";

import avatarImg from "../../../assets/images/memoji/user.png";

export default function HeaderUser() {
  // Redux
  const dispatch = useDispatch();
  const customise = useSelector(state => state.customise)
  const [currentEmail, setCurrentEmail] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const currentUser = useSelector(state => state.login.currentUser)
  const handleLogout = async () => {
    window.location.href = ('/pages/login');
    localStorage.setItem('token', '')
    localStorage.setItem('dashboardRestaurant', '')
    dispatch({
      type: "GET_CURRENT_USER",
      currentUser: null
    });
  }
  // Sidebar
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  // Get Data 
  useEffect(() => {
    setCurrentEmail(currentUser ? currentUser.Email : '')
  }, []);

  function handlePwd(record) {
    setSidebarOpen(!sidebarOpen)
  }
  const menu = (
    <Menu theme={customise.theme == "light" ? "light" : "dark"}>
      <Menu.Item
        key={0}
        icon={
          <RiLockPasswordLine
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <a href="#" onClick={handlePwd}>Password</a>
      </Menu.Item>
      <Menu.Item
        key={1}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <a href="#" onClick={handleLogout}>Log Out</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar src={avatarImg} size={32} />
          <p className="da-header-left-text-item da-input-label da-text-color-black-100 da-text-color-dark-0 da-ml-16 da-mb-0">
            {currentEmail}
          </p>
        </Col>
      </Dropdown>
      {
          sidebarOpen ? 
            <PwdModal open={sidebarOpen}
            toggleSidebar={toggleSidebar}
            currentUser={currentUser} 
            /> 
            : 
            <></>
        }
    </Col>
  );
}