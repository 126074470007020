import { Col, Layout, Row } from "antd";
const { Footer } = Layout;

import themeConfig from '../../../configs/themeConfig.jsx';

export default function MenuFooter() {
  return (
    <Footer className="da-bg-color-black-10 da-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col span={24}>
          <p className="da-badge-text da-mb-0 da-text-color-dark-30" style={{textAlign:"center"}}>
            Copyright © Skoopin 2024
          </p>
        </Col>
      </Row>
    </Footer>
  );
}