const menuitemState = {
    allergens: [],
    menuItemAllergens: [],
    nutritionFacts: [],
    menuItemNutritionFacts: [],
    menuitems: [],
    manualEntryMenuItems: [],
    data: [],
    total: 1,
    params: {},
    selectedMenuitem: null
  };
  
  const menuitemReducer = (state = menuitemState, action) => {
    switch (action.type) {
      case "GET_MENUITEMS":
        return { ...state, menuitems: action.data, total: action.total};

      case "GET_MANUAL_MENUITEMS":
          return { ...state, manualEntryMenuItems: action.data};
  
      case "GET_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "ADD_MENUITEM":
        return { ...state };
  
      case "EDIT_MENUITEM":
        return { ...state };

      case "EDIT_MENUITEM_ALLERGENS":
          return { ...state };

      case "EDIT_MENUITEM_NUTRITION_FACTS":
        return { ...state };

      case "DELETE_MENUITEM":
        return { ...state };
      
      case "GET_ALLERGENS":
        return { ...state, allergens: action.data };

      case "GET_MENUITEM_ALLERGENS":
        return { ...state, menuItemAllergens: action.data };

      case "GET_NUTRITION_FACTS":
        return { ...state, nutritionFacts: action.data };

      case "GET_MENUITEM_NUTRITION_FACTS":
        return { ...state, menuItemNutritionFacts: action.data };
  
      default:
        return { ...state };
    }
  };
  
  export default menuitemReducer;