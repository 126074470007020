import { Modal, Col, Row, Divider, Input, Form, Button, Select, message } from "antd";
import sha256 from 'crypto-js/sha256';

// Redux
import { useDispatch } from "react-redux";
import { editUser } from "../../../redux/user/userActions";

export default function PwdModal({ open, toggleSidebar, currentUser}) {
  
  // Redux
  const dispatch = useDispatch();
  
  // Form Finish
  const onFinish = async (values) => {
    if (values.ConfirmPassword !== values.Password) {
      message.error('The entered confirmation password is different from the password');
      return;
    } 
    toggleSidebar();
    let data = {}
    data.ID = values.ID
    data.Password = sha256(values.Password).toString();
    dispatch(
      editUser(currentUser.ID, data)
    );
  };

  const passwordValidator = (rule, value, callback) => {
    let passwordRegexDigit = /^(?=.*\d)/gm;
    let passwordRegexUpper = /^(?=.*[A-Z])/gm;
    let passwordRegexLower = /^(?=.*[a-z])/gm;
    let passwordRegexSpecial = /^(?=.*[^\w\d\s:])/gm;
    let passwordRegexLength = /^([^\s]){8,64}$/gm;

    if (!value) {
      callback('Required');
      return
    }
    if (!passwordRegexDigit.test(value)) {
      callback('Must contain at least one number.');
      return
    }
    if (!passwordRegexUpper.test(value)) {
      callback('Must contain at least one uppercase letter.');
      return
    }
    if (!passwordRegexLower.test(value)) {
      callback('Must contain at least one lowercase letter.');
      return
    }
    if (!passwordRegexSpecial.test(value)) {
      callback('Must contain at least one special character and no whitespace.');
      return
    }
    if (!passwordRegexLength.test(value)) {
      callback('Must contain 8-64 characters.');
      return
    }
    callback()
  }

  return (
    <Modal
      visible={open}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={toggleSidebar}
      width={'30%'}
      footer={null}
      bodyStyle={{ padding: 24 }}
    >
      <Form
        layout="vertical"
        name="InfoForm"
        preserve={false}
        initialValues={currentUser}
        onFinish={onFinish}
      >
        <Row gutter={[8, 0]}>
          <Col md={24} span={24}>
            <Form.Item name="Email" label="Email" 
              rules={[{ required: true, message: 'Please enter a valid email address', type: 'email' }]}>
              <Input disabled={true}/>
            </Form.Item>
          </Col>
          {
            <>
              <Col md={24} span={24}>
                <Form.Item name="Password" label="Password" rules={[{required: true, message: ''}, { validator: passwordValidator }]}>
                  <Input.Password allowClear={true} visibilityToggle={false}/>
                </Form.Item>
              </Col>
              <Col md={24} span={24}>
                <Form.Item name="ConfirmPassword" label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Required'
                    }, 
                    ({getFieldValue}) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('Password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Must be the same as password input");
                      }
                    })
                  ]}
                >
                  <Input.Password allowClear={true} visibilityToggle={false}/>
                </Form.Item>
              </Col>
            </>
          }
          <Divider />

          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              {"Update"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}