import { Card, Col, Row } from 'antd';
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import Chart from "react-apexcharts";

export default function AvePortions(props) {
  let { values, title, colors } = props;
  if (!title) {
    title = "Average Portions";
  }
  if (!colors) {
    colors = '#267AF8';
  }

  const history = useHistory()
  const [chartData, setChartData] = useState();
  useEffect(() => {
    if (!values) {
      return;
    }
    const _chartData = {
      series: [
        {
          name: "Portion",
          data: [],
        }
      ],
      options: {
        chart: {
          fontFamily: "Manrope, sans-serif",
          type: "bar",
          events: { // 添加柱状图数据的点击事件
            dataPointSelection: function (event, chartContext, config) {
              history.push({pathname: '/pages/executive/portion', state: {
                initMenuItemID: values[config.dataPointIndex]
                // foodCategory: 'portion',
                // dateRange: '',
              }})
            }
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: '60%',
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
  
        dataLabels: {
          enabled: false,
        },
  
        grid: {
          borderColor: "#DFE6E9",
          row: {
            opacity: 0.5,
          },
          yaxis: {
            lines: {
                show: false
            }
          },
        },
        fill: {
          opacity: 1,
          type: "solid",
        },
        stroke: {
          show: true,
          width: 4,
          curve: "straight",
          colors: ["transparent"],
        },
        xaxis: {
          axisTicks: {
            show: true,
          },
          tickAmount: 3,
  
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "12px",
            },
            formatter: function(val) {
              return !isFinite(val) ? Number(1).toFixed(1) : val.toFixed(1);
            }
          },
          categories: []
        },
        legend: {
          offsetX: 40,
          position: "top",
          markers: {
            radius: 12,
          },
        },
        colors: colors,
        tooltip: {
          enabled: true,
          x: {
            formatter: function(val) {
              return val
            }
          },
          y: {
            formatter: function(val, obj) {
              let index = obj.dataPointIndex
              return `
              <span class="portionsTitle">Total Portions:</span><span class="leftoverLb">${values[index].TotalOrderDetails.toFixed(0)}</span><br>
              <span class="portionsTitle">Days Served:</span><span class="leftoverLb">${(values[index].DaysServed).toFixed(0)}</span><br>
              <span class="portionsTitle">Avg. Daily Portions:</span><span class="leftoverLb">${val.toFixed(0)}</span>`
            },
            title: {
              formatter: () => {
                return ''
              },
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "12px",
            },
            minWidth: 40,
            maxWidth: 520,
            formatter: function(value) {
              if (typeof(value) === 'string') {
                let tmp = value.split(' ')
                if(tmp.length > 3) {
                  return tmp[0] + " " + tmp[1] + " " + tmp[2] + '...'
                } else {
                  return value
                }
              } else {
                return value
              }
            }
          },
        },
      },
    }

    values.forEach((value, index) => {
      _chartData.series[0].data.push(value.AvgPortionsPerDay);
      _chartData.options.xaxis.categories.push(value.MenuItemName);
    });
    setChartData(_chartData);
  }, [values]);

  return (
    <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
      <Row style={{marginLeft: '-15px'}}>
        <Col span={24}>
          <Row justify="space-between" align="top" style={{marginLeft: '15px'}}>
            <Row align="bottom">
              <h4 className="da-mr-8">
                {title}
              </h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <div id="chart">
            {
              chartData &&
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
                legend="legend"
              />
            }
          </div>
        </Col>
      </Row>
      <h5 className="da-mr-8" style={{textAlign: 'center', fontSize: '14px'}}>
        Average Daily Portions
      </h5>
    </Card>
  );
}
