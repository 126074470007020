const menuItemCategoryState = {
  menuItemCategories: [],
  total: 0
};

const menuItemCategoryReducers = (state = menuItemCategoryState, action) => {
  switch (action.type) {
    case "LIST_MENU_ITEM_CATEGORIES":
      return { ...state, menuItemCategories: action.data, total: action.total };
    case "ADD_MENU_ITEM_CATEGORY":
      return { ...state };
    case "EDIT_MENU_ITEM_CATEGORY":
      return { ...state };
    case "DELETE_MENU_ITEM_CATEGORY":
      return { ...state };   
    default:
      return { ...state };
  }
}

export default menuItemCategoryReducers;