import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Menu } from "antd";
import navigation from "../../../../navigation/vertical";

const { SubMenu } = Menu;
const ssoHiddenPages = ['/pages/account', '/pages/user']

export default function MenuItem(props) {
    const { onClose } = props;

    // Redux
    const customise = useSelector(state => state.customise)
    const currentUser = useSelector(state => state.login.currentUser)

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/")

    // Method(s) to help render the navigation menu
    function userHasAccess(item) {
        return(!item.access || item.access && currentUser && item.access === currentUser.Role);
    }

    // Menu
    const splitLocationUrl =
        splitLocation[splitLocation.length - 2] +
        "/" +
        splitLocation[splitLocation.length - 1];

    const menuItem = navigation.map((item) => {
        if (!userHasAccess(item)) {
            return;
        }
        if (ssoHiddenPages.indexOf(item.navLink) >= 0) {
            let authConfig = localStorage.getItem('auth-config') ? JSON.parse(localStorage.getItem('auth-config')) : ''
            if (authConfig && authConfig.strategy === 'saml') {
                return;
            }
        }
        if (item.header) {
            // return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
        }
        if (item.children) {
            // don't render this menu item if it doesn't have any children accessible to the current user
            let noAccessibleChildren = true;
            item.children.every(child => {
                if (userHasAccess(child)) {
                    noAccessibleChildren = false;
                }
                return noAccessibleChildren;
            });
            if (noAccessibleChildren) {
                return;
            }
            
            // render children elements that the current user has access to
            return (
                <SubMenu key={item.id} icon={item.icon} title={item.title}>
                    {item.children.map((child) => {
                        if (!child.children) {
                            if (userHasAccess(child)) {
                                const childNavLink = child.navLink.split("/");
                                return (
                                    // Level 2
                                    <Menu.Item
                                        key={child.id}
                                        className={
                                            splitLocationUrl ===
                                                childNavLink[childNavLink.length - 2] +
                                                "/" +
                                                childNavLink[childNavLink.length - 1]
                                                ? "ant-menu-item-selected"
                                                : "ant-menu-item-selected-in-active"
                                        }
                                        style={{ marginLeft: '1.5rem' }}
                                        onClick={onClose}
                                    >
                                        <Link to={child.navLink}>{child.title}</Link>
                                    </Menu.Item>
                                );
                            }
                        } else {
                            return (
                                // Level 3
                                <SubMenu key={child.id} title={child.title}>
                                    {child.children.map((grandchild) => {
                                        const grandchildNavLink = grandchild.navLink.split("/");

                                        return (
                                            <Menu.Item
                                                key={grandchild.id}
                                                className={
                                                    splitLocationUrl ===
                                                        grandchildNavLink[grandchildNavLink.length - 2] +
                                                        "/" +
                                                        grandchildNavLink[grandchildNavLink.length - 1]
                                                        ? "ant-menu-item-selected"
                                                        : "ant-menu-item-selected-in-active"
                                                }
                                                onClick={onClose}
                                            >
                                                <Link to={grandchild.navLink}>{grandchild.title}</Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        }
                    })}
                </SubMenu>
            );
        } else {
            const itemNavLink = item.navLink.split("/");

            return (
                // Level 1
                <Menu.Item
                    key={item.id}
                    icon={item.icon}
                    onClick={onClose}
                    className={
                        splitLocation[splitLocation.length - 2] +
                            "/" +
                            splitLocation[splitLocation.length - 1] ===
                            itemNavLink[itemNavLink.length - 2] +
                            "/" +
                            itemNavLink[itemNavLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                    }
                >
                    <Link to={item.navLink} style={{ color: '#0010F7' }}>{item.title}</Link>
                </Menu.Item>
            );
        }
    })

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={[
                splitLocation.length === 5
                    ? splitLocation[splitLocation.length - 3]
                    : null,
                splitLocation[splitLocation.length - 2],
            ]}
            theme={customise.theme == "light" ? "light" : "dark"}
        >
            {menuItem}
        </Menu>
    );
}