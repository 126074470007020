import React, { useEffect, useState } from "react";
import request from "../../../utils/request";
// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, message } from "antd";
import loginLogo from '../../../assets/images/logo/logo.png';
import { login } from "../../../redux/login/loginActions";
import store from "../../../redux/store";
import { Redirect } from 'react-router-dom'

export default function Login() {
  // Redux
  const currentUser = useSelector(state => state.login.currentUser)
  //Tradition, SSO, Mixture
  const [authMode, setAuthMode] = useState('');
  const [SSOUrl, setSSOUrl] = useState('');
  
  const authStrategy = {
    local: 'local',
    saml: 'saml',
    cognito: 'cognito',
  }

  useEffect(async () => {
    let response = await request.get('/auth/configuration');
    if (response?.data?.data) {
      let _auth = response.data.data
      localStorage.setItem('auth-config', _auth ? JSON.stringify(_auth) : '')
      setAuthMode(_auth.strategy)
      if (_auth.strategy === 'saml') {
        setSSOUrl(_auth.saml.SAML_ENTRY_POINT)
      }
    } else {
      setAuthMode(authStrategy.local)
    }
  }, [])
  // Form Finish
  const handleSubmit = async (values) => {
    store.dispatch(login({...values}))
  };

  const handleCorpAccount = () => {
    window.location.href = SSOUrl
  }
  return (
    <>
    <Row gutter={[32, 0]} className="da-authentication-page">
      <Col lg={24} span={24} className="da-py-sm-0 da-py-md-64">
        <Row className="da-h-100" align="middle" justify="center">
          <Col
            xxl={6}
            xl={8}
            lg={8}
            md={8}
            sm={8}
            className="da-px-sm-8 da-pt-24 da-pb-48"
          >
            <div style={{textAlign:"center", marginTop: '10%'}}>
              <img src={loginLogo} style={{width: '60%'}}></img>
            </div>
              <Form
                layout="vertical"
                name="basic"
                onFinish={handleSubmit}
                className="da-mt-sm-16 da-mt-32"
              >                  
                {
                  ([authStrategy.local, authStrategy.cognito].indexOf(authMode) >= 0) && (
                    <>
                      <Form.Item name="Email" label="Username:" className="da-mb-16" rules={[{ required: true, message: 'Please enter a valid email address', type: 'email' }]}>
                        <Input id="error" />
                      </Form.Item>
                      <Form.Item name="Password" label="Password:" className="da-mb-8" rules={[{ required: true, message: 'Required' }]}>
                        <Input.Password id="warning2" />
                      </Form.Item>
                      <Form.Item className="da-mt-16 da-mb-8">
                        <Button block type="primary" htmlType="submit">
                          Sign In
                        </Button>
                      </Form.Item>
                    </>
                  )
                }
                {
                  ([authStrategy.saml].indexOf(authMode) >= 0)  && (
                    <Form.Item className="da-mt-16 da-mb-8">
                      <Button block onClick={() => handleCorpAccount()}>
                        Continue with Corp Account
                      </Button>
                    </Form.Item>
                  )
                }
              </Form>
          </Col>
        </Row>
      </Col>
    </Row>
    {
      currentUser && currentUser.Role === 'system admin' ? (
        <Redirect to="/pages/dashboard/kitchen" />
      ) : (currentUser && currentUser.Role === 'account admin' ? (
        <Redirect to="/pages/executive/dashboard" />
      ) : <></>)
    }
    </>
  );
}