import request from "../../utils/request";

export const listMenuItems = async (params) => {
    return request.get(`/menuitems`, {
        params: params
    });
};

export const getAllMenuItems = async (params) => {
    let menuItems = [];
    let resp;
    do {
        resp = await listMenuItems(params);
        menuItems = menuItems.concat(resp.data.data);
        params.current = resp.data.current + 1;
    } while(resp?.data?.total > resp?.data?.current * resp?.data?.pageSize)
    return menuItems;
};

export const getMenuItemQuery = (restaurantID, searchData, page, pageSize, selectCategories) => {
  let query = {
    RestaurantID: restaurantID || '',
    current: page,
    pageSize: pageSize || 8,
    Display: 1
  };

  if (searchData) {
    query.Name = searchData;
  }
  if (selectCategories) {
    query.MenuItemCategoryID = selectCategories;
  }
  return query;
};

export const getManualMenuItemQuery = (restaurantID) => {
  let query = {
    RestaurantID: restaurantID || '',
    current: 1,
    pageSize: 0,
    Display: 1,
    IsManualCombo: 1
  };

  return query;
};