const restaurantState = {
    restaurants: [],
    data: [],
    total: 1,
    params: {},
    selectedRestaurant: null,
  };
  
  const restaurantReducer = (state = restaurantState, action) => {
    switch (action.type) {
      case "GET_RESTAURANTS":
        return { ...state, restaurants: action.data };

      case "GET_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "GET_RESTAURANT":
        return { ...state, selectedRestaurant: action.selectedRestaurant };
  
      case "ADD_RESTAURANT":
        return { ...state };
  
      case "EDIT_RESTAURANT":
        return { ...state };

      case "DELETE_RESTAURANT":
        return { ...state };

      case "SELECT_RESTAURANT":
        return { ...state, selectedRestaurant: action.data };
  
      default:
        return { ...state };
    }
  };
  
  export default restaurantReducer;