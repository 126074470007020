import request from "../../utils/request";
import sha256 from 'crypto-js/sha256';
import { message } from "antd";
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
} from 'amazon-cognito-identity-js';

export const login = (body, options) => {
    return async (dispatch) => {
        let response = await request.get('/auth/configuration');
        if (response?.data?.data.strategy === 'cognito') {
            return awsLogin(body, response.data.data, dispatch);
        } else {
            return userPasswordLogin(body, dispatch);
        }
    };
};

async function awsLogin(body, config, dispatch) {
    let promise = new Promise(function (resolve, reject) {
        let authenticationData = {
            Username: body.Email,
            Password: sha256(body.Password).toString(),
        }
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        var poolData = {
            UserPoolId: config.cognito.AWS_USER_POOL_ID,
            ClientId: config.cognito.AWS_CLIENT_ID,
        };
        const userData = {
            Username: body.Email,
            Pool: new CognitoUserPool(poolData),
        }
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res) {
                resolve(res)
            },
            onFailure: function (err) {
                message.error(err.message || 'Operation failed')
                reject(err)
            },
        })
    })
    let awsres = await promise;
    console.log(awsres);
    localStorage.setItem('token', awsres.accessToken.jwtToken);
    localStorage.setItem('refreshToken', awsres.refreshToken.token);
    let ret = await request.get('/auth');
    console.log(ret);
    if (ret && ret.data && ret.data.errorCode === 0 && ret.data.data) {
        const userInfo = ret.data.data.user
        if (userInfo && userInfo.Role === 'account admin') {
            if (userInfo.Account && userInfo.Account.Restaurants && userInfo.Account.Restaurants.length > 0) {
                userInfo.currentRestaurant = userInfo.Account.Restaurants[0].ID
            } else {
                userInfo.currentRestaurant = ''
            }
        }
        await dispatch({
            type: "GET_CURRENT_USER",
            currentUser: userInfo
        });
    }
    return ret;
}

async function userPasswordLogin(body, dispatch) {
    let response = await request.post("/auth", {
        AuthType: 'Password',
        Password: sha256(body.Password).toString(),
        Email: body.Email
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    ).catch((err) => console.log(err));

    if (response && response.data && response.data.errorCode === 0 && response.data.data) {
        const userInfo = response.data.data.user
        localStorage.setItem('token', response.data.data.token)
        if (userInfo && userInfo.Role === 'account admin') {
            userInfo.currentRestaurant = ''
            if (userInfo.Account && userInfo.Account.Restaurants && userInfo.Account.Restaurants.length > 0) {
                for (let m = 0; m < userInfo.Account.Restaurants.length; m++) {
                    if (userInfo.Account.Restaurants[m].Status) {
                        userInfo.currentRestaurant = userInfo.Account.Restaurants[m].ID
                        break;
                    }
                }
            }
        }
        await dispatch({
            type: "GET_CURRENT_USER",
            currentUser: userInfo
        });
    } else {
        localStorage.setItem('token', '')
    }
    return response;
}