import React, { useEffect, useState } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Select, Form, Button, DatePicker, Tooltip } from "antd";
import { sortData } from "../../../utils/tool";
import moment from 'moment-timezone';
import BreadCrumbs from '../../../layout/components/content/breadcrumbs';
import { getAllMenuItems } from "../../../api/menuitem";
import { listMenuItemCategories } from "../../../api/menuitemcategory";
import { RiDownload2Fill, RiDownloadCloudFill} from "react-icons/ri";

var currentTimeZone = '';
const maxMenuItemCount = 1;
const maxMenuItemNameLen = 16;

export default function Header(props) {
  const { breadCrumb, onFilterChange, initialValues, onExport, onPrint } = props;
  // Redux
  const dispatch = useDispatch();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const currentUser = useSelector(state => state.login.currentUser);
  const restaurants = useSelector(state => state.login.currentUser.Account.Restaurants);
  const dateFormat = 'MM/DD/YYYY';
  const [dateRange, setDateRange] = useState([]);
  const [menuItems, setMenuItems] = useState();
  const [menuItemCategories, setMenuItemCategories] = useState();
  const [menuItemID, setMenuItemID] = useState();
  const [exceedMenuItemIDCount, setExceedMenuItemIDCount] = useState(0);
  const [categoryID, setCategoryID] = useState(null);
  const [filterPref, setFilterPref] = useState({});

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const restaurant = restaurants.find(r => { return r.ID === currentUser.currentRestaurant });
    if (restaurant) {
      currentTimeZone = restaurant.Timezone;
      localStorage.setItem('dashboardTimezone', currentTimeZone);
    }
  }, [restaurants]);

  useEffect(() => {
    // TODO, handle restaurant change here
    // rather than src\layout\components\header\HeaderText.jsx
    getCategoryItems().catch((err) => console.log(err));
  }, [currentUser.currentRestaurant]);

  async function getCategoryItems() {
    const resp = await listMenuItemCategories({RestaurantID: currentUser.currentRestaurant || ''});
    const categories = resp.data.data;
    categories.sort((a, b) => { return (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : -1; });
    setMenuItemCategories(categories);
    if (initialValues && initialValues.MenuItemCategoryID) {
      setCategoryID(initialValues.MenuItemCategoryID);
      getMenuItems(initialValues.MenuItemCategoryID.value)
    }
  }
  async function getMenuItems(menuItemCategoryID) {
    const menuItems = await getAllMenuItems({ RestaurantID: currentUser.currentRestaurant || '', MenuItemCategoryID: menuItemCategoryID || ''});
    menuItems.sort((a, b) => { return (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : -1; });
    setMenuItems(menuItems)
    return menuItems
  }
  async function init() {
    if (!currentUser) {
      return;
    }
    const _filterPref = loadFilters();
    if (initialValues && initialValues.MenuItemID) {
      const _menuItemID = []
      if (initialValues.MenuItemID.MenuItemID instanceof Array) {
        for (const item of initialValues.MenuItemID.MenuItemID) {
          _menuItemID.push({
            key: item.MenuItemID,
            label: item.MenuItemName,
            value: item.MenuItemID,
          });
        }
      } else {
        _menuItemID.push({
          key: initialValues.MenuItemID.MenuItemID,
          label: initialValues.MenuItemID.MenuItemName,
          value: initialValues.MenuItemID.MenuItemID,
        });
      }
      _filterPref.MenuItemID = _menuItemID;

      // when drill down from dashboard, should clear category filter
      // _filterPref.MenuItemCategoryID = undefined;
    }
    setMenuItemID(_filterPref.MenuItemID);
    setFilterPref(_filterPref);

    // calculate exceedMenuItemIDCount when drill down or load pref from local storage
    const _exceedMenuItemIDCount = _filterPref.MenuItemID ? _filterPref.MenuItemID.length - maxMenuItemCount : 0;
    setExceedMenuItemIDCount(_exceedMenuItemIDCount);

    if ((!initialValues || !initialValues.MenuItemCategoryID) && _filterPref && _filterPref.MenuItemCategoryID) {
      setCategoryID(_filterPref.MenuItemCategoryID);
      getMenuItems(_filterPref.MenuItemCategoryID.value)
    }
    setDateRange(_filterPref.DateRange);
    if (onFilterChange) {
      onFilterChange(_filterPref);
    }
    localStorage.setItem('ExecInsightFilter', JSON.stringify(_filterPref));
  }

  function loadFilters() {
    let _filterPref = localStorage.getItem('ExecInsightFilter');

    if (_filterPref) {
      _filterPref = JSON.parse(_filterPref);
    } else {
      _filterPref = {};
    }
    if (!_filterPref.DateRange) {
      _filterPref.DateRange = [moment().startOf('day').subtract(1, 'month'), moment().startOf('day')];
    } else {
      try {
        _filterPref.DateRange = _filterPref.DateRange.map(date => moment(date));
      } catch (error) {
        console.log('loadFilters error', error);
      }
    }
    return _filterPref;
  }

  function _onFilterChange(values) {
    const _filterPref = Object.assign(filterPref, values);
    localStorage.setItem('ExecInsightFilter', JSON.stringify(_filterPref));
    setFilterPref(_filterPref);
    if (onFilterChange) {
      onFilterChange(_filterPref);
    }
  }

  function dateChange(dates, dateStrings) {
    setDateRange(dates);
    _onFilterChange({ DateRange: dates });
  }

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  async function handleExportCsv(e) {
    if (onExport) {
      onExport(filterPref);
    }
  }
  async function handleExportPDF(e) {
    if (onPrint) {
      onPrint(filterPref);
    }
  }

  function menuItemsChange(value, options, noRefresh) {
    setMenuItemID(value);
    let _exceedMenuItemIDCount = value.length - maxMenuItemCount;
    setExceedMenuItemIDCount(_exceedMenuItemIDCount);
    if (!noRefresh) {
      _onFilterChange({ MenuItemID: value });
    }
  }

  async function categoryChange(value, option) {
    setCategoryID(value);
    let currentMenuItems = await getMenuItems(value.value)
    const _selectedMenuItemID = currentMenuItems
      ? currentMenuItems.map(item => {
          return {
            key: item.ID,
            label: item.Name,
            value: item.ID,
          };
        })
      : [];
    menuItemsChange(_selectedMenuItemID, null, true);
    _onFilterChange({ MenuItemCategoryID: value, MenuItemID: _selectedMenuItemID });
  }
  return (
    <Form
      layout="horizontal"
      name="basic"
      initialValues={{ remember: true }}
    >
      <Row gutter={[32, 0]} justify="space-between" style={{ marginBottom: '5px' }}>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col xl={6} lg={0} md={0}>
              <BreadCrumbs
                breadCrumbParent="Executive Insights"
                breadCrumbParent2={breadCrumb}
              />
            </Col>
            <Col xl={18} lg={24} style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
              <Select
                labelInValue={true}
                value={categoryID}
                placeholder='Food Category'
                onChange={categoryChange}
                style={{ width: '12em', marginRight: '1em', textAlign: 'left' }}
              >
                <Option value={null} key={'all'}>- All Categories -</Option>
                {
                  menuItemCategories && menuItemCategories.map((item, index) => {
                    return <Option value={item.ID} key={item.ID}>{item.Name}</Option>
                  })
                }
              </Select>
              {
                window.location.pathname !== '/pages/executive/dashboard' && (
                  <>
                    <Select
                      mode="multiple"
                      labelInValue={true}
                      value={menuItemID}
                      placeholder='Food Items'
                      onChange={menuItemsChange}
                      maxTagCount={maxMenuItemCount}
                      maxTagTextLength={maxMenuItemNameLen}
                      maxTagPlaceholder={`+${exceedMenuItemIDCount} more`}
                      allowClear
                      style={{ width: '18em', textAlign: 'left', marginRight: '1em' }}
                    >
                      {
                        menuItems && menuItems.map((item, index) => {
                          return <Option value={item.ID} key={item.ID}>{item.Name}</Option>
                        })
                      }
                    </Select>
                  </>
                )
              }
              <RangePicker
                allowClear={false}
                disabledDate={disabledDate}
                onChange={dateChange}
                style={{width: '18rem'}}
                defaultValue={dateRange}
                value={dateRange}
                format={dateFormat}
              />
              {
                onPrint && (
                  <>
                    <Tooltip title="Print">
                      <Button icon={<RiDownloadCloudFill />} onClick={handleExportPDF} style={{ marginLeft: '1rem' }} />
                    </Tooltip>
                  </>
                )
              }
              {
                onExport && (
                  <>
                    <Tooltip title="Export as CSV">
                      <Button icon={<RiDownload2Fill />} onClick={handleExportCsv} style={{ marginLeft: '1rem' }} />
                    </Tooltip>
                  </>
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
