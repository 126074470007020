const accountState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedAccount: null,
  };
  
  const accountReducer = (state = accountState, action) => {
    switch (action.type) {
      case "GET_ACCOUNTS":
        return { ...state, allData: action.data };
  
      case "GET_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "GET_ACCOUNT":
        return { ...state, selectedAccount: action.selectedAccount };
  
      case "ADD_ACCOUNT":
        return { ...state };
  
      case "EDIT_ACCOUNT":
        return { ...state };

      case "DELETE_ACCOUNT":
        return { ...state };
  
      default:
        return { ...state };
    }
  };
  
  export default accountReducer;