import React, { useEffect, useState } from "react";
import { Col, Row, Card, Tooltip } from 'antd';
import numeral from 'numeral';
import { RiScales2Line } from "react-icons/ri";
import { AiOutlinePieChart, AiOutlineBarChart } from "react-icons/ai";
import { IoTrashBinOutline } from "react-icons/io5";

export default function TotalRow(props) {
  const [loading, setLoading] = useState(props.loading);
  const { totalData } = props;

  return (
    <Row gutter={[32, 0]} wrap={false} style={{textAlign: "center"}}>
      <Col xl={6} md={6} span={24}>
        <Card className="da-border-color-black-40">
          <Row justify='center'>
            <Col className="da-statistic-icon-bg da-mr-16 da-mb-xs-16 da-bg-color-primary-4 da-bg-color-dark-primary iconDashBg">
              <h3><RiScales2Line className="da-text-color-primary-1 da-text-color-dark-primary-2 remix-icon" /></h3>
            </Col>
            <Col className="da-mt-8">
              <h3 className="da-mb-4">
                {numeral(totalData.totalWeight).format('0,0') + ' lb'}
              </h3>
              <p className="da-p1-body da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                Weight Consumed
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xl={6} md={6} span={24}>
        <Card className="da-border-color-black-40">
          <Row justify='center'>
            <Col className="da-statistic-icon-bg da-mr-16 da-mb-xs-16 da-bg-color-secondary-4 da-bg-color-dark-secondary iconDashBg">
              <h3><AiOutlinePieChart className="da-text-color-secondary-1 remix-icon" /></h3>
            </Col>
            <Col className="da-mt-8">
              <h3 className="da-mb-4">
                {numeral(totalData.totalPortions).format('0,0')}
              </h3>
              <p className="da-p1-body da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                Portions Consumed
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xl={6} md={6} span={24}>
        <Tooltip placement="topLeft" title = {
              totalData.totalLeftover === "N/A" ?
                "Total Leftover is not tracked for this food category" : null
            }>
          <Card className="da-border-color-black-40">
            <Row justify='center'>
              <Col className="da-statistic-icon-bg da-mr-16 da-mb-xs-16 da-bg-color-danger-4 da-bg-color-dark-danger iconDashBg">
                <h3><IoTrashBinOutline className="da-text-color-danger-1 remix-icon" /></h3>
              </Col>
              <Col className="da-mt-8">
                <h3 className="da-mb-4">
                  {totalData.totalLeftover === "N/A" ? "n/a" : numeral(totalData.totalLeftover).format('0,0') + ' lb'}
                </h3>
                <p className="da-p1-body da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                  Total Leftover
                </p>
              </Col>
            </Row>
          </Card>
        </Tooltip>
      </Col>
      <Col xl={6} md={6} span={24}>
        {/* Daily Leftover card */}
        <Tooltip placement="topLeft" title = {
              totalData.percentLeftover === "N/A" ?
                "Daily Leftover is not tracked for this food category" : null
            }>
          <Card className="da-border-color-black-40">
            <Row justify='center'>
              <Col className="da-statistic-icon-bg da-mr-16 da-mb-xs-16 da-bg-color-warning-4 da-bg-color-dark-warning iconDashBg">
                <h3><AiOutlineBarChart className="da-text-color-warning-1 remix-icon" style={{transform: "scale(1.2)"}}/></h3> {/* stats chart icon */}
              </Col>
              <Col className="da-mt-8"> {/* numeral figure and card label */}
                <h3 className="da-mb-4">
                  {totalData.percentLeftover === "N/A" ? "n/a" : numeral(totalData.percentLeftover).format('0,0') + ' %'}
                </h3>
                <p className="da-p1-body da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                  Average Leftover Per Menu Item
                </p>
              </Col>
            </Row>
          </Card>
        </Tooltip>
      </Col>
    </Row>
  )
}