import { Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, BrowserRouter, useHistory } from "react-router-dom";
import moment from 'moment-timezone';

export default function HeaderText() {
  // Redux
  const dispatch = useDispatch();
  // Location
  const location = useLocation();
  const history = useHistory();

  const { Option } = Select;
  const currentUser = useSelector(state => state.login.currentUser)

  function resetFilterPref() {
    let _filterPref = localStorage.getItem('ExecInsightFilter');
    if (!_filterPref) {
      const dateRange = [moment().startOf('day').subtract(1, 'month'), moment().startOf('day')];
      _filterPref = {
        DateRange: dateRange
      }
    } else {
      try {
        _filterPref = JSON.parse(_filterPref);
        if (_filterPref.DateRange) {
          _filterPref.DateRange = _filterPref.DateRange.map(date => moment(date));
        }
      } catch (error) {
        console.log('loadFilters error', error);
      }
    }
    _filterPref.MenuItemID = null;
    _filterPref.MenuItemCategoryID = null;
    localStorage.setItem('ExecInsightFilter', JSON.stringify({}));
  }

  function handleChange (value) {
    let obj = Object.assign({}, currentUser)
    // TODO, improve the code to reset filter preference when restaurant selection change
    // Would be better to handle the selection chnage in src\view\pages\executive\Header.jsx
    // But it may need to refactor the page structure.
    resetFilterPref();
    obj.currentRestaurant = value
    dispatch({
      type: "GET_CURRENT_USER",
      currentUser: obj
    });
    history.go()
  }
  return (
    <Col
      xl={16}
      lg={14}
      className="da-d-flex-center"
    >
      {
        (currentUser && currentUser.Role === 'account admin' ||
        window.location.pathname.indexOf('/pages/executive') >= 0) && (
          <>
            <p className="da-header-left-text-item da-input-label da-text-color-black-100 da-text-color-dark-0 da-ml-16 da-mb-0">
              Current Restaurant: 
            </p>
            <Select
              style={{
                width: "20em",
                marginLeft: '10px'
              }}
              defaultValue={currentUser.currentRestaurant}
              onChange={handleChange}
            >
              {
                currentUser.Account && currentUser.Account.Restaurants && currentUser.Account.Restaurants.filter((item) => item.Status !== false).map((it,idx) =>{
                  return  <Option value={it.ID} key={it.ID}>{it.Name}</Option>
                })
              }
            </Select>
          </>
        )
      }
    </Col>
  );
}