import axios from "axios";
import { message } from "antd";
import { errorCode } from './error'
import store from "../redux/store";
import qs from "qs";

let request = axios.create({
  interceptors: true,
  timeout: 7000,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  }
});

request.interceptors.request.use(config => {
  config.url = '/api/v1' + config.url
  if (config.url.indexOf('api/v1/auth/configuration') < 0) {
    localStorage.setItem('lastRequestTime', Date.now())
  }
  if (window.location.pathname === '/pages/culinarian') {
    config.headers['Authorization'] = 'Bearer '+ localStorage.getItem('update-token') || ''
  } else {
    config.headers['Authorization'] = 'Bearer '+ localStorage.getItem('token') || ''
  }
  return config
})

// 添加响应拦截
request.interceptors.response.use(
  response => {
      /**
      * result为非0是抛错
      */
    const res = response.data
    // Square Connection Error goes back to User
    if (res.errorCode && res.errorCode < 0 && res.errorCode != errorCode.SquareAuth.code) {
      message.error(res.errorMessage || errorCode.Fail.msg)

      if (res.errorCode === errorCode.Auth.code) {
        setTimeout(() => {
          localStorage.setItem('token', '')
          store.dispatch({
            type: "GET_CURRENT_USER",
            currentUser: null
          });
          window.location.href = ('/pages/login');
        }, 1500)
      }
      return Promise.reject(res)
    } else {
      return response
    }
  },
  error => {
    // const { status, data } = error.response;
    // switch (status) {
    //   case 401:
    //     alert('登录失败,请重新登录');
    //   break;
    //   case 422:
    //     alert(data.msg);
    //   break;
    //   default:
    //     alert('请稍后重试');
    // }
  }
)
export default request;