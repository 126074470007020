import React, { useEffect, useState } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import Header from "../Header";
import TotalRow from "./components/TotalRow";
import Consumption from "./components/Consumption";
import Leftover from "./components/Leftover";
import { getRestaurantsStats } from "../../../../api/stat";
import AvePortions from "./components/AvePortions";

export default function executive() {
  // Redux
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.login.currentUser)
  const [totalData, setTotalData] = useState({
    loading: true,
  });
  const [dataByMenuItem, setDataByMenuItem] = useState();
  const [mostPopularItems, setMostPopularItems] = useState([]);
  const [leastPopularItems, setLeastPopularItems] = useState([]);

  async function getRollUpsByMenus(values) {
    const MenuItemCategoryID = values.MenuItemCategoryID ? values.MenuItemCategoryID.value : undefined;
    const DateRange = values.DateRange;
    const StartDate = DateRange ? DateRange[0].format('YYYY-MM-DD') : undefined;
    const EndDate = DateRange ? DateRange[1].format('YYYY-MM-DD') : undefined;
    let resp = await getRestaurantsStats(currentUser.currentRestaurant, {
      TimeFrame: 'day',
      MenuItemCategoryID: MenuItemCategoryID,
      StartDate: StartDate,
      EndDate: EndDate,
      WithMenuItem: true,
    });
    return resp?.data?.data;
  }

  async function handleFilterChange(values) {
    const rollUpsByMenus = await getRollUpsByMenus(values);
    let dayDiff = 1;

    if (values.DateRange) {
      dayDiff = values.DateRange[1].diff(values.DateRange[0], 'days') + 1;
    }
    if (rollUpsByMenus) {
      const sumByAllMenuItems = {
        loading: false,
        totalWeight: 0,
        totalPortions: 0,
        totalLeftover: "N/A",
        percentLeftover: "N/A",
        validPercentages: 0
      }
      const sumByMenuItem = {};

      for (const rollup of rollUpsByMenus) {
        sumByAllMenuItems.totalWeight += rollup.RollUp?.TotalWeight * 0.0625 ?? 0;
        sumByAllMenuItems.totalPortions += rollup.RollUp?.TotalOrderDetails ?? 0;
        const rollupLeftover = rollup.RollUp?.Leftover ?? 0;
        const rollupTotalOrderDetails = rollup.RollUp?.TotalOrderDetails ?? 0;

        if (rollup.RollUp && !isNaN(rollupLeftover)) {
          if (sumByAllMenuItems.totalLeftover === "N/A") {
            sumByAllMenuItems.totalLeftover = 0;
          }
          sumByAllMenuItems.totalLeftover += rollupLeftover * 0.0625;
        }
        if (!sumByMenuItem[rollup.MenuItemID]) {
          sumByMenuItem[rollup.MenuItemID] = {
            MenuItemID: rollup.MenuItemID,
            MenuItemName: rollup.MenuItem.Name,
            TotalWeight: 0,
            TotalOrderDetails: 0,
            TotalLeftover: "N/A",
            TotalCover: 0,
            DaysServed: 0
          }
        }
        const item = sumByMenuItem[rollup.MenuItemID];
        item.TotalWeight += rollup.RollUp?.TotalWeight * 0.0625 ?? 0;
        if (rollupTotalOrderDetails > 0) {
          item.TotalOrderDetails += rollupTotalOrderDetails;
          item.DaysServed++;
        }
        if (rollup.RollUp && !isNaN(rollupLeftover)) {
          if (item.TotalLeftover === "N/A") {
            item.TotalLeftover = 0;
          }
          if (rollupTotalOrderDetails >= 5) {  // calculating "Total Leftover" and "Daily Leftover" using portions >= 5 qualifier
            item.TotalLeftover += rollupLeftover * 0.0625;
            if (sumByAllMenuItems.percentLeftover === "N/A") {
              sumByAllMenuItems.percentLeftover = 0;
            }
            if (!isNaN(rollup.RollUp.TotalWeight)) {
              sumByAllMenuItems.percentLeftover += rollupLeftover / (rollupLeftover + rollup.RollUp.TotalWeight);
              sumByAllMenuItems.validPercentages++;
            }
          }
        }
        item.TotalCover += rollup.RollUp.TotalCover;
      }
      for (const id in sumByMenuItem) {
        const item = sumByMenuItem[id];
        item.AvgPortionsPerDay = item.TotalOrderDetails / item.DaysServed;
      }
      if (sumByAllMenuItems.percentLeftover !== 0 && sumByAllMenuItems.validPercentages !== 0) {
        sumByAllMenuItems.percentLeftover = Math.ceil(sumByAllMenuItems.percentLeftover / sumByAllMenuItems.validPercentages * 100);
      }

      const mostPopular = Object.values(sumByMenuItem)
        .sort((a, b) => b.AvgPortionsPerDay - a.AvgPortionsPerDay).slice(0, 5);
      const leastPopular = Object.values(sumByMenuItem)
        .sort((a, b) => a.AvgPortionsPerDay - b.AvgPortionsPerDay).slice(0, 5);

      setMostPopularItems(mostPopular);
      setLeastPopularItems(leastPopular);
      setTotalData(sumByAllMenuItems);
      
      // TODO: use array instead of object in dataByMenuItem
      setDataByMenuItem(sumByMenuItem);
    }
  }

  return (
    <Row gutter={[32, 0]}>
      <Col span={24}>
        <Header
          breadCrumb={'Dashboard'}
          onFilterChange={handleFilterChange}
        />
      </Col>
      <Col span={24} className="da-mt-32">
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <TotalRow
              loading={totalData.loading}
              totalData={totalData}
            />
          </Col>
          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col xl={16} span={24} className="da-overflow-hidden">
                <Consumption consumptionData={dataByMenuItem} />
              </Col>
              <Col xl={8} span={24}>
                <AvePortions
                  values={mostPopularItems}
                  title='Most Popular'
                  colors="#97F4FF"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col xl={16} span={24} className="da-overflow-hidden">
                <Leftover leftoverData={dataByMenuItem} />
              </Col>
              <Col xl={8} span={24}>
                <AvePortions
                  values={leastPopularItems}
                  title='Least Popular'
                  colors="#FFE393"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
