const stationState = {
    stations: [],
    mainstations: [],
    panLayouts: [],
    data: [],
    total: 1,
    params: {},
    selectedRestaurant: null,
  };
  
  const stationReducer = (state = stationState, action) => {
    switch (action.type) {
      case "GET_STATIONS":
        return { ...state, stations: action.data };
      case "GET_MAINSTATIONS":
        return { ...state, mainstations: action.data };
      case "GET_PANLAYOUTS":
        return { ...state, panLayouts: action.data };
      case "GET_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "GET_STATION":
        return { ...state, selectedRestaurant: action.selectedRestaurant };
  
      case "ADD_STATION":
        return { ...state };
  
      case "EDIT_STATION":
        return { ...state };

      case "UPGRADE_VERSION":
        return { ...state };

      case "CANCEL_UPGRADE":
        return { ...state };

      case "DELETE_STATION":
        return { ...state };
  
      default:
        return { ...state };
    }
  };
  
  export default stationReducer;