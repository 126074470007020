import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useHistory } from 'react-router-dom'
import { sortData, sortPercentageData } from "../../../../../utils/tool";

export default function Leftover({leftoverData}) {
  const history = useHistory()
  const [chartData, setChartData] = useState();
  useEffect(() => {
    if (!leftoverData) {
      return;
    }
    const leftoverDataArray = Object.values(leftoverData);
    const _chartData = {
      series: [
        {
          name: "Consumed (lb)",
          data: [],
        },
        {
          name: "Leftover (lb)",
          data: [],
        },
      ],
      options: {
        chart: {
          fontFamily: "Manrope, sans-serif",
          type: "bar",
          // stacked: true,
          events: { // 添加柱状图数据的点击事件
            dataPointSelection: function (event, chartContext, config) {
              history.push({pathname: '/pages/executive/leftover', state: {
                initMenuItemID: leftoverDataArray[config.dataPointIndex]
              }})
            }
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
  
        dataLabels: {
          enabled: false,
        },
  
        grid: {
          borderColor: "#DFE6E9",
          row: {
            opacity: 0.5,
          },
        },
        fill: {
          opacity: 1,
          type: "solid",
        },
        stroke: {
          show: true,
          width: 4,
          curve: "straight",
          colors: ["transparent"],
        },
        xaxis: {
          axisTicks: {
            show: true,
          },
          tickAmount: 8,
  
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "13px",
            },
            formatter: function(value) {
              if (!value.length) {
                return value;
              }
              let tmp = value.split(' ')
              if(tmp.length > 3){
                return tmp[0] + " " + tmp[1] + " " + tmp[2] + '...';
              } else {
                return value;
              }
            }
          },
          categories: [],
        },
        legend: {
          horizontalAlign: "right",
          offsetX: 40,
          fontSize: '14px',
          position: "top",
          markers: {
            radius: 12,
          },
        },
        colors: ["#26A0FC", "#FFC212"],
        tooltip: {
          enabled: true,
          x: {
            formatter: function(val) {
              return val
            }
          },
          y: {
            formatter: function(val, obj) {
              let series = obj.series
              let index = obj.dataPointIndex
              // no need to convert oz to lb here
              return `
              <span class="leftoverTitle">Served:</span><span class="leftoverLb">${Number((series[0][index] + series[1][index])).toFixed(1)}lb</span><br>
              <span class="leftoverTitle">Consumed:</span><span class="leftoverLb">${Number(series[0][index]).toFixed(1)}lb</span><span class="leftoverRatio" style="color:#26A0FC;">${(series[0][index] / (series[0][index] + series[1][index]) * 100).toFixed(0)}%</span><br>
              <span class="leftoverTitle">Leftover:</span><span class="leftoverLb">${Number(series[1][index]).toFixed(1)}lb</span><span class="leftoverRatio" style="color:#FFC212;">${(series[1][index] / (series[0][index] + series[1][index]) * 100).toFixed(0)}%</span>`
            },
            title: {
              formatter: () => {
                return ''
              },
            }
          },
          marker: {
            show: false,
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "14px",
            },
            formatter: (value) => {
              return !isFinite(value) ? Number(1).toFixed(1) : Number(value).toFixed(1);
            },
            align: 'right'
          },
        },
      },
    }
    sortPercentageData(leftoverDataArray, 'TotalLeftover', 'TotalWeight', 'desc')
    for (const leftover of leftoverDataArray.slice(0, 12)) {
      _chartData.series[0].data.push(leftover.TotalWeight);
      _chartData.series[1].data.push(leftover.TotalLeftover);
      _chartData.options.xaxis.categories.push(leftover.MenuItemName);
    }
    setChartData(_chartData);
  }, [leftoverData]);
  return (
    <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
      <Row style={{height: '430px'}}>
        <Col className="da-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="da-mr-8">Leftover By Menu</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <div style={{minHeight: '330px'}}>
            {
              chartData &&
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={365}
                legend="legend"
              />
            }
          </div>
        </Col>
      </Row>
    </Card>
  );
}